// Import FirebaseAuth and firebase.
import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AuthContext } from './RequireAuth';
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Toolbar, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import HCAppBar from './HCAppBar';
interface IProps {
    firebase: any
}

interface IState {
}

class SignInScreen extends Component<IProps, IState> {
    uiConfig: {
        // Popup signin flow rather than redirect flow.
        signInFlow: string;
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: string;
        callbacks: any;
        // We will display Google and Facebook as auth providers.
        signInOptions: string[];
    };

    constructor(props: IProps) {
        super(props)
        this.uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
            signInSuccessUrl: '/dashboard',
            callbacks: {
                // Avoid redirects after sign-in.
                signInSuccessWithAuthResult: async (result: any) => {
                    this.context.signin(result.user);
                    return true;
                },
            },
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID
            ],
        };
    }

    render() {
        return (
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <HCAppBar toggleColorMode={this.context.toggleColorMode} />

                <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={this.props.firebase.auth()} />
                </Container>
            </Box>
        );
    }
}

SignInScreen.contextType = AuthContext;
export default SignInScreen;