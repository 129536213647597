import { ColorModeContext } from '../App';
import { Component, SyntheticEvent } from "react";
import { withRouter } from './withRouter';
import Podcast from "../models/podcast";
import PodcastList from "../PodcastList";
import { getLoggedInUser } from "../utils/firebase";
import SmartChapter from "../models/smartChapter";
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Toolbar, IconButton, Button, Grid } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Trigger from '../models/trigger';
import Episode from '../models/episode';
import HCAppBar from '../HCAppBar';
interface IProps {
    navigate: any
}

interface IState {
    podcasts: any,
    selectedPodcast: Podcast
}

class Dashboard extends Component<IProps, IState>  {
    auth: any;

    componentDidMount() {
        this.fetchUserPodcasts();
    }

    sections = [
        { key: 'Home', content: 'Home', active: true },
    ];

    fetchUserPodcasts = async () => {
        let user = await getLoggedInUser();

        if (user) {
            const authToken = await user.getIdToken(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'tokenId': authToken
                }
            };

            let response = await (await fetch('/api/studio/podcasts', requestOptions)).json();
            console.log(response);
            this.setState({
                podcasts: response.map((item: { name: string; rssUrl: string; id: string; smartChapters: Array<SmartChapter>, triggers: Array<Trigger>, transcriptTokens: number, imageUrl: string, episodes: Array<Episode> }) => new Podcast(item.name, item.rssUrl, item.id, item.smartChapters, item.triggers, item.transcriptTokens, item.imageUrl, item.episodes)),
            })
        } else {
            console.log("signed out");
        }
    }

    handlePodcastItemClicked = async (event: SyntheticEvent | null, newSelectedPodcast: Podcast) => {
        console.log('clicked:' + JSON.stringify(newSelectedPodcast));
        this.props.navigate(`${newSelectedPodcast.id}`, { state: { selectedPodcast: newSelectedPodcast } });
    }

    handleAddNewPodcast = async(event: SyntheticEvent | null) => {
        console.log('clicked:');
        this.props.navigate(`/addPodcast`, { state: { selectedPodcast: null } });
    }

    static contextType = ColorModeContext;

    render() {

        return (
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <HCAppBar toggleColorMode={this.context.toggleColorMode} />

                <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
                <Grid container spacing={2} columns={10}>
            <Grid item xs={12}>
              <p className="title">Podcast Actions</p>
            </Grid>
            <Grid item xl={2}>
                <Button variant="outlined" onClick={(e) => {this.handleAddNewPodcast(e)}}>Add New Podcast</Button>
            </Grid>
            </Grid>
                            <Grid container spacing={2} columns={10} sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>


                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" href="/dashboard">
                                        Podcasts
                                    </Link>
                                </Breadcrumbs>
                                <PodcastList podcasts={this.state?.podcasts} handleItemClicked={this.handlePodcastItemClicked!} />
                                </Grid>
                                </Grid>                            
                </Container>
            </Box >
        )
    }
}

export default withRouter(Dashboard);