import { ColorModeContext } from '../App';
import { Component, SyntheticEvent } from "react";
import { withRouter } from './withRouter';
import Podcast from "../models/podcast";
import PodcastList from "../PodcastList";
import { getLoggedInUser } from "../utils/firebase";
import SmartChapter from "../models/smartChapter";
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Toolbar, IconButton, Button, Grid, Stack, TextField } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Trigger from '../models/trigger';

interface IProps {
    navigate: any
}

interface IState {
    podcasts: any,
    selectedPodcast: Podcast,
    episodes: any
    rssUrl: string
}

class AddNewPodcast extends Component<IProps, IState>  {
    auth: any;

    componentDidMount() {
        this.setState({rssUrl: ''});
    }

    sections = [
        { key: 'Home', content: 'Home', active: true },
    ];

    handleAddNewRSSFeed = async () => {
        let user = await getLoggedInUser();

        if (user) {
            
            const authToken = await user.getIdToken(true);
            let rawBody = JSON.stringify({
                'podcastId': this.state.rssUrl
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'tokenId': authToken
                },
                body: rawBody
            };

            let response = await (await fetch('/api/studio/submitPodcast', requestOptions)).json();
            console.log(response);
            if (response.status == 'error') {
                alert(response.message);
            } else {
                this.props.navigate(`/dashboard`);
            }
        } else {
            console.log("signed out");
        }
    }

    fetchPodcastEpisodes = async (selectedPodcast: Podcast) => {
        let user = await getLoggedInUser();

        if (user) {
            const authToken = await user.getIdToken(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'tokenId': authToken
                }
            };

            let response = await (await fetch('/api/studio/episodes', requestOptions)).json();
            console.log(response);
        } else {
            console.log("signed out");
        }
    }

    handlePodcastItemClicked = async (event: SyntheticEvent | null, newSelectedPodcast: Podcast) => {
        console.log('clicked:' + JSON.stringify(newSelectedPodcast));
        this.props.navigate(`${newSelectedPodcast.id}`, { state: { selectedPodcast: newSelectedPodcast } });
    }

    static contextType = ColorModeContext;

    render() {

        return (
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <AppBar position="absolute">
                    <Toolbar>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            HyperCatcher Studio
                        </Typography>
                        <IconButton sx={{ ml: 1 }} onClick={this.context.toggleColorMode} color="inherit">
                            <Brightness4Icon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
                <Stack spacing={2} >
                    <Typography variant="body1" gutterBottom>
                        The RSS Feed For Your Podcast
                    </Typography>
                    { this.state ? 
                            <TextField fullWidth label="Podcast RSS URL" value={this.state.rssUrl} onChange={(e) => {
                                var rssUrl = this.state.rssUrl
                                rssUrl = e.target.value;
                                this.setState({ rssUrl });
                                }}/>
                        : null 
                    }
                        </Stack>

                        <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
                            <Button variant="contained" onClick={this.handleAddNewPodcast.bind(this)}>Submit Podcast</Button>
                        </Stack>
                </Container>
            </Box >
        )
    }
    
    handleAddNewPodcast = (event: SyntheticEvent | null) =>  {
        console.log('clicked:');
        this.handleAddNewRSSFeed();
    }
}

export default withRouter(AddNewPodcast);