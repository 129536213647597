import { Component } from "react";
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Button, Stack, TableContainer, Paper, Toolbar } from '@mui/material';
import Podcast from "./models/podcast";
import Trigger from "./models/trigger";
import { withRouter } from "./routes/withRouter";
import { getLoggedInUser } from "./utils/firebase";
import SuggestedChaptersTriggersTable from "./routes/SuggestedChaptersTriggersTable";
import _ from "lodash-es";

interface IProps {
    params_url: string | null,
    podcastId: any,
    location: any,
}

interface IState {
    podcastId: string,
    studioPodcastDetail: Podcast | null,
    triggers: Array<Trigger>
}

class SuggestedChaptersTriggerController extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            podcastId: this.props.podcastId,
            studioPodcastDetail: null,
            triggers: []
        }
        this.getStudioPodcast();
    }

    handleUpdateTriggers = async () => {

        //valudate triggers
        for (let i = 0; i < this.state.triggers.length; i++) {
            const trigger = this.state.triggers[i];
            if (_.isEmpty(trigger.triggerWord)) {
                alert('trigger word cannot be empty');
                return;
            }
            const wordCount = trigger.triggerWord.split(/\S+/).length - 1;
            if (wordCount < 2) {
                alert('trigger word must be at least 2 words');
                return;
            }
        }

        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'tokenId': authToken
            },
            body: JSON.stringify({
                'triggers': this.state.triggers,
                'podcastId': this.state.podcastId
            })
        };
        await fetch('/api/podcast/suggestedChaptersTriggers', requestOptions);
    }

    getStudioPodcast = async () => {

        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);

        const requestOptions = {
            method: 'POST',
            headers: {
                'tokenId': authToken
            },
            body: JSON.stringify({
                'podcastId': this.props.podcastId
            })
        };

        let response = await (await fetch('/api/studio/podcast', requestOptions)).json();
        if (response != null) {
            let podcastDetail = Podcast.initFromJson(response);
            this.setState({
                studioPodcastDetail: podcastDetail
            })
        }
    }

    getLatestTriggers = (triggersResult: any) => {
        console.log('get latest triggers');
        console.log(triggersResult);
        if (!_.isEqual(triggersResult, this.state.triggers)) {
            this.setState({ triggers: triggersResult });
        }
    }

    render() {
        return <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <AppBar position="absolute">
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        HyperCatcher Studio
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>

                <Breadcrumbs >
                    <Link href="/dashboard"><Typography> Home </Typography></Link>
                    <Link href={`/dashboard/${this.props.podcastId}`}><Typography>{`${this.props.podcastId}`}</Typography></Link>
                    <Typography>Smart Chapters</Typography>
                </Breadcrumbs>

                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <p className="title">Publish Options</p>
                        <Stack spacing={2} direction="row">
                            <Button variant="outlined" onClick={() => { this.handleUpdateTriggers() }}>Save Changes</Button>
                        </Stack>
                    </Paper>
                </Container >

                <section className="topic">
                    <h2>Chapter Triggers</h2>
                </section>

                <div className='nes-table-responsive'>
                    {this.state.podcastId
                        ? <SuggestedChaptersTriggersTable
                            studioPodcastDetail={this.state.studioPodcastDetail}
                            getLatestTriggers={this.getLatestTriggers}
                        /> : null}
                </div>
            </Container>
        </Box >
    }
}

export default withRouter(SuggestedChaptersTriggerController);