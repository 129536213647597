import { Component, SyntheticEvent } from "react";
import EpisodeList from "../EpisodeList";
import Episode from "../models/episode";
import { getLoggedInUser } from "../utils/firebase";
import { withRouter } from "./withRouter";
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Button, Stack, Toolbar, IconButton, Tabs, Tab, TextField, Tooltip, Modal, Grid, Skeleton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { ColorModeContext } from '../App';
import TabPanel from './tabPanel';
import _ from "lodash";
import ValueFormBuilder from "./valueFormBuilder";
import { PodcastValueBlockFormState, ValueBlockRecipientFormState, FullValueBlockFormState } from './FormStateModel';
import HCAppBar from "../HCAppBar";


interface IProps {
    podcastId: any,
    location: any,
    navigate: any,
    theme: any,
}

interface IState {
    episodes: any| null,
    selectedTabValue: number,
    valueBlocks: any,
    theme: any,
    funding: any,
    open: boolean,
}

class DashboardEpisodes extends Component<IProps, IState>  {
    auth: any;
    rssAugmentation: any;
    rssUrl: string;
    valueBlockFormDefaults: any; 
    valueBlockFormRecipientsDefaults: any;

    constructor(props: any) {
        super(props)

        console.log(props.location);
        this.rssUrl = `https://feed.hypercatcher.com/${this.props.podcastId}/feed.rss`;
        this.valueBlockFormDefaults = {type: '', method: '', suggested: ''};
        this.valueBlockFormRecipientsDefaults = [{name: '', type: '', address: '', split: '', customKey: '', customValue: ''}];
        this.state = {selectedTabValue: 0, episodes: null, valueBlocks: null, theme: this.props.theme, funding: {url: '', description: ''}, open: false};
        
    }

    style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };



    componentDidMount() {
        this.fetchUserEpisodes();
        this.getPodcastRssAugmentation();
    }

    handleOpen() {
        this.setState({open: true});
    }

    handleClose() {
        this.setState({open: false});
    }

    handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({selectedTabValue: newValue});
      };

    fetchUserEpisodes = async () => {
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId
        })
        var requestOptions = {
            method: 'POST',
            headers: {
                'tokenId': authToken,
                'podcastId': this.props.podcastId
            },
            body: raw
        };


        let response = await fetch("/api/studio/episodes", requestOptions);
        let result = await response.json();
        
        const episodes = result.map((item: any) => {
            let seconds = 0;
            if (item.pubDateTime) {
                seconds = item.pubDateTime._seconds;
            }
            return new Episode(item.title, '', item.id, seconds, item.imageUrl, item.chapters, item.community_chapters, item.podcast_transcript_url);
        });
        
        this.setState({
            episodes: episodes
        });
    }

    handleEpisodeItemClicked = async (event: SyntheticEvent | null, newSelectedEpisode: Episode) => {
        console.log('clicked:' + JSON.stringify(newSelectedEpisode));
        this.props.navigate(`episode/${encodeURIComponent(newSelectedEpisode.guid)}`, { state: { selectedEpisode: newSelectedEpisode, selectedPodcast: this.props.podcastId } });
    }

    handleSmartChapterLinkClicked = async (currentPodcast: String) => {
        this.props.navigate(`smartChapters`, { state: { currentPodcast: currentPodcast } });
    }

    handleSuggestedChaptersTriggersLinkClicked = async (currentPodcast: String) => {
        this.props.navigate(`suggestedChaptersTriggers`, { state: { currentPodcast: currentPodcast } });
    }

    handleEpisodesRefreshClicked = async () => {
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'tokenId': authToken
            },
            body: raw
        };

        console.log(requestOptions);
        let response = await fetch("/api/studio/refresh/podcastEpisodes", requestOptions);
        let result = await response.json();
        this.fetchUserEpisodes();
    }

    getPodcastRssAugmentation = async () => {
        console.log('getPodcastRssAugmentation');
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'tokenId': authToken
            },
            body: raw
        };

        console.log(requestOptions);
        let response = await fetch("/api/studio/podcast/augment/get", requestOptions);
        if (response) {
            let result = await response.json();
            if (response.status === 200) {
                this.setState({
                    funding: result.funding,
                });
    
                if (this.valueBlockFormDefaults.type === '' && this.valueBlockFormDefaults.method === '' && this.valueBlockFormDefaults.suggested === '') {
                    if (result.value.valueBlock !== null) {
                        this.valueBlockFormDefaults = {'type': result.value.valueBlock.type, 'method': result.value.valueBlock.method, 'suggested': result.value.valueBlock.suggested};
                        this.setState({
                            valueBlocks: {'type': result.value.valueBlock.type, 'method': result.value.valueBlock.method, 'suggested': result.value.valueBlock.suggested}
                        });
                    }
                    if (result.value.valueBlockRecipients !== null) {
                        this.valueBlockFormRecipientsDefaults = result.value.valueBlockRecipients;
                    }
                }
            }
        }
    }

    handleUpdatePodcastRssAugmentation = async () => {
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        const fullValueBlockFormState = new FullValueBlockFormState(this.valueBlockFormDefaults, this.valueBlockFormRecipientsDefaults);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId,
            'rssAugmentation': {'funding': this.state.funding, 'value': fullValueBlockFormState}
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'tokenId': authToken
            },
            body: raw
        };

        console.log(requestOptions);
        let response = await fetch("/api/studio/podcast/augment", requestOptions);
        let result = await response.json();
        this.fetchUserEpisodes();
    }

    handlePublishHyperfeedClicked = async () => {
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'tokenId': authToken
            },
            body: raw
        };

        console.log(requestOptions);
        let response = await fetch("/api/studio/podcast/publish", requestOptions);
        let result = await response.json();
        if (result.success !== true) {
            alert(JSON.stringify(result.message));
        }
        this.fetchUserEpisodes();
    }

    handlePublishToIndexClicked = async () => {
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'tokenId': authToken
            },
            body: raw
        };

        console.log(requestOptions);
        let response = await fetch("/api/studio/podcast/publishToIndex", requestOptions);
        let result = await response.json();
        alert(JSON.stringify(result));
    }

    static contextType = ColorModeContext;
    render() {
        return (
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >

                <HCAppBar toggleColorMode={this.context.toggleColorMode} />

                <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>

                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link href="/dashboard" > Home </Link>
                        <Typography>{`${this.props.podcastId}`}</Typography>
                    </Breadcrumbs>
                    {
                        this.state ? (
                            <Grid container spacing={2} columns={10} sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>


                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" href="/dashboard">
                                        Episodes
                                    </Link>
                                </Breadcrumbs>
                                <EpisodeList episodes={this.state.episodes} handleItemClicked={this.handleEpisodeItemClicked!} />
                                </Grid>
                                </Grid>                            
                        ) : <Skeleton variant="rectangular" width={210} height={60} />
                    }
                    <Container className='nes-container with-title is-centered'>
                        <p className="title">Podcast Settings</p>
                        <Stack spacing={2} direction="row">
                            <Button variant="outlined" onClick={() => { this.handleSmartChapterLinkClicked(this.props.podcastId) }}>Smart Chapters</Button>
                            <Button variant="outlined" onClick={() => { this.handleSuggestedChaptersTriggersLinkClicked(this.props.podcastId) }}>Chapter Triggers</Button>
                            <Button variant="outlined" onClick={() => { this.handleEpisodesRefreshClicked() }}>Refresh Podcast Episodes</Button>
                        </Stack>
                    </Container >
                    <Container className='nes-container with-title is-centered'>
                        <p className="title">HyperFeed</p>
                        <Stack spacing={2} direction="row">
                        <Button variant="outlined" onClick={() => { this.handlePublishHyperfeedClicked() }}>Publish Hyperfeed</Button>
                        <Button variant="outlined" onClick={() => { this.handleUpdatePodcastRssAugmentation() }}>Update Hyperfeed</Button>
                            <Tooltip title={this.rssUrl ?? ''} arrow>
                            <Button variant="outlined" onClick={() => this.handleCopyRSSUrl()} >Copy HyperFeed URL</Button>
                            </Tooltip>
                        <Button variant="outlined" onClick={() => {this.handleOpen()}}>Publish To Index</Button>
                                <Modal
                                open={this.state.open}
                                onClose={() => {this.handleClose()}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                >
                                <Box sx={this.style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Warning!
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    Publishing this feed to the index does not replace your original Podcast feed.
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    This action will create a new feed that will be indexed by the Podcast Index and will be available on Podcasting 2.0 apps.
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    This action will create a duplicate feed differentiated by the addition of "- HyperFeed" to the feed name.
                                    </Typography>
                                    <Button variant="outlined" onClick={() => {this.handlePublishToIndexClicked()}}>Publish To Index</Button>
                                </Box>
                                </Modal>
                        </Stack>
                        <Tabs
                            value={this.state.selectedTabValue}
                            onChange={this.handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            >
                            <Tab label="Funding" />
                            <Tab label="Value" />
                        </Tabs>
                        <TabPanel value={this.state.selectedTabValue} index={0}>
                        <Stack spacing={2} >
                            <TextField fullWidth label="Description" value={this.state.funding.description} onChange={(e) => {
                                var funding = {...this.state.funding}
                                funding.description = e.target.value;
                                this.setState({ funding });
                                }}/>
                            <TextField fullWidth value={this.state.funding.url} label="Url"  onChange={(e) => {
                                    var funding = {...this.state.funding}
                                    funding.url = e.target.value;
                                    this.setState({ funding });
                                }}/>
                        </Stack>
                        </TabPanel>
                        <TabPanel value={this.state.selectedTabValue} index={1}>
                            <ValueFormBuilder podcastValueBlockFormProp={this.valueBlockFormDefaults} 
                            podcastValueBlockFormUpdate={this.handlePodcastValueBlockFormUpdate}
                            valueBlockFormRecipientsFormProp={this.valueBlockFormRecipientsDefaults}
                            podcastValueBlockRecipientFormUpdate={this.podcastValueBlockRecipientFormUpdate}/>
                        </TabPanel>
                    </Container>
                </Container>
            </Box >
        )
    }
    async handlePublishToIndex() {
        let user = await getLoggedInUser();
        const authToken = await user.getIdToken(true);
        let raw = JSON.stringify({
            'podcastId': this.props.podcastId
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'tokenId': authToken
            },
            body: raw
        };

        console.log(requestOptions);
        let response = await fetch("/api/studio/podcast/publishToIndex", requestOptions);
        let result = await response.json();
        this.fetchUserEpisodes();
    }

    podcastValueBlockRecipientFormUpdate = (recipients: any) => {
        console.log(recipients);
        this.valueBlockFormRecipientsDefaults = recipients;
    }

    handlePodcastValueBlockFormUpdate = (valueBlocks: any) => {
        console.log(valueBlocks);
        this.valueBlockFormDefaults = valueBlocks;
    }

    handleValueBlockRecipientsFormUpdate = (recipients: any) => {
        console.log(recipients);
        this.valueBlockFormRecipientsDefaults = recipients;
    }

    getValueBlocks = (valueBlocks: any) => {
        console.log(valueBlocks);
        if (!_.isEqual(valueBlocks, this.state.valueBlocks)) {
          this.setState({ valueBlocks: valueBlocks });
        }
    }

    handleCopyRSSUrl = () => {
        console.log(this.rssUrl);
        this.copyTextToClipboard(this.rssUrl);
      };
    
      fallbackCopyTextToClipboard = (text: string) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
    
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
    
        document.body.removeChild(textArea);
    }
    
    copyTextToClipboard = (text: string) => {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text);
    }

}

export default withRouter(DashboardEpisodes);